<template>
    <div class="modal-card" style="width: 100% !important;">
        <header class="modal-card-head">
            <p class="modal-card-title">Uusi tilitapahtuma</p>
        </header>
        <section class="modal-card-body">
            <b-field label="Valitse koulutuskumppani">
                <b-select placeholder="Valitse koulutuskumppani" v-model="partner">
                    <option
                        v-for="option in partners"
                        :value="option.id"
                        :key="option.id">
                        {{ option.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Valitse kurssi">
                <b-select placeholder="Valitse kourssi" v-model="course">
                    <option
                        v-for="option in courses"
                        :value="option.id"
                        :key="option.id">
                        {{ option.name }}
                    </option>
                    <option value="custom">Muu koulutus</option>
                </b-select>
            </b-field>
            <b-field v-if="course == 'custom'" label="Kurssi">
                <b-input v-model="newTransaction.meta"></b-input>
            </b-field>
            <b-field v-if="course == 'custom'" label="Hinta">
                <b-input v-model="newTransaction.amount"></b-input>
            </b-field>
            <b-field v-if="course != 'custom' && course != ''" label="Hinta">
                <b-input v-model="newTransaction.amount"></b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Peruuta"
                @click="$parent.close()" />
            <b-button
                @click="createTransaction"
                label="Luo tilitapahtuma"
                type="is-primary" />
        </footer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: [
        'company',
        'selectedUser'
    ],
    data() {
        return {
            courseData: {},
            course: '',
            partner: {},
            partners: [],
            courses: [],
            newTransaction: {
                course_id: '',
                meta: '',
                amount: 0,
                type: 'purchase',
                from_type: 'enduser',
                to_type: 'education_partner'
            }
        }
    },
    watch: {
        partner: function (partner) {
            this.getCourses(partner);
        },
        course: function (course) {
            if(course != '' && course != 'custom') {
                this.getCourse(course);
            }
            console.log("COURSE",course);
        }
    },
    computed: {
        activePartner() {
            return this.partner;
        }
    },
    mounted() {
        this.getPartners();
    },
    methods: {
        getPartners() {
            axios.get('education-partners').then((response) => {
                this.partners = response.data;
            })
        },
        getCourses(partnerId) {
            this.courses = [];
            axios.get('courses/byeducator/'+partnerId).then((response) => {
                this.courses = response.data;
                console.log(response.data);
            })
            console.log(partnerId);
        },
        getCourse(courseId) {
            this.courses = [];
            axios.get('courses/'+courseId).then((response) => {
                this.courseData = response.data;
                this.newTransaction = {
                    course_id: response.data.id,
                    meta: response.data.name,
                    amount: response.data.price,
                    type: 'purchase',
                    from_type: 'enduser',
                    to_type: 'education_partner',
                    from: this.selectedUser.id,
                    to: this.partner.id
                }

                console.log("course data", response.data);
            }).catch((error) => {
                console.log(error);
            })
        },
        createTransaction() {
            console.log('createTransaction');
            axios.post('purchase',{ amount: this.newTransaction.amount, memo: this.newTransaction.meta}).then((response) => {
                console.log(response.data);
                this.$emit('created');
            }).catch((error) => {
                console.log(error);
            })
        },
     
    },
}
</script>