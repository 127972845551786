<template>
     <div class="container content">
        <div>
            <b-breadcrumb separator="has-succeeds-separator" size="is-medium">
                <b-breadcrumb-item tag='router-link' to="/admin-dashboard">Admin</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' to="/admin-companies">Yritykset</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' :to="'/admin-companies/'+$route.params.id">{{ company_name }}</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' :to="'/admin-companies/'+$route.params.id+'/employees/'+$route.params.employeeId" active>{{ employee_name }}</b-breadcrumb-item>
            </b-breadcrumb>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Työntekijän tiedot</p>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Etunimi">
                                <b-input v-model="employee.first_name"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-6">
                            <b-field label="Sukunimi">
                                <b-input v-model="employee.last_name"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Sähköposti">
                                <b-input v-model="employee.email"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-6">
                            <b-field label="Puhelin">
                                <b-input v-model="employee.mobile"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div style="text-align: right; margin-top: 20px;">
                        <b-button @click="saveEmployee" size="is-medium">
                           Tallenna muutokset
                        </b-button>
                    </div>
                </article>            
            </div>
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Tilitapahtumat</p>
                    <div v-if="employee.user_id == null">
                    <strong>Käyttäjä ei ole vielä aktivoinut tiliä!</strong><br><br><br>
                    Kutsulinkki:<br><small>{{employee.invite_link}}</small>
                    </div>
                    <div v-if="employee.user_id != null">
                    <b-table hoverable :data="transactions" :columns="transactions_cols"></b-table>
                    <div style="text-align: right; margin-top: 30px;">
                        <b-button size="is-medium"
                            @click="modals.new_transaction = true"
                            icon-left="plus">
                            Lisää uusi tapahtuma
                        </b-button>
                    </div>
                </div>
                </article>
            </div>
        </div>
        <b-modal
            v-model="modals.new_transaction"
            has-modal-card
            full-screen
            :can-cancel="true">
            <NewTransactionForm :selectedUser="userData" @created="transactionCreated" @close="modals.new_transaction = false" />
        </b-modal>  
    </div>
</template>
<script>
import axios from 'axios';
import NewTransactionForm from '@/views/admin/forms/NewTransaction';
//import eventBus from '@/eventBus';
export default {
    components: {
            NewTransactionForm
        },
    data() {
        return {
            modals: {
                new_transaction: false,
            },
            transactions_cols: [
                {
                    field: 'id',
                    label: 'Id'
                },
                {
                    field: 'type',
                    label: 'Tyyppi'
                },
                {
                    field: 'amount',
                    label: 'Määrä'
                },
                {
                    field: 'timestamp',
                    label: 'Aika'
                }
            ],
            employee_name: "",
            user_name: "",
            userData: {
                id: "",
                accounts: []
            },
            employee: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                user_id: null,
                invite_link: ""
            },
            company_name: "",
            company: {
                id: "",
                name: "",
                vat: "",
                address: "",
                city: "",
                zip: "",
                billing_type: "paper",
                e_invoice_address: "",
                e_invoice_provider: ""
            },
        }
    },
    computed: {
        transactions() {
            let transactions = [];
            if(this.userData.accounts.length > 0) {
                this.userData.accounts.forEach((account) => {
                    account.deposits.forEach((deposit) => {
                        transactions.push(deposit);
                    })
                    account.withdrawals.forEach((withdrawal) => {
                        transactions.push(withdrawal);
                    })
                })
            }
            return transactions;
        }
    },
    methods: {
        transactionCreated() {
            this.getEmployeeData();
            this.modals.new_transaction = false;
        },
        openAddTransactionModal() {

        },
        saveEmployee() {

        },
        getEmployeeData() {
            axios.get('/employees/'+ this.$route.params.employeeId).then((response) => {
                console.log('EMPLOYEEDATA:' + this.$route.params.employeeId,response.data);
                this.employee = response.data;
                this.employee_name = response.data.first_name + " " +  response.data.last_name;
                if(this.employee.user_id) {
                    this.getUserData(this.employee.user_id);
                }
            }); 
        },
        getUserData(userId) {
            axios.get('/users/'+ userId).then((response) => {
                console.log('USERDATA: '+userId,response.data);
                this.userData = response.data;
            }); 
        },
        updateUserData() {
            axios.get('/users/'+ this.$route.params.userId).then((response) => {
                this.user = response.data;
                this.user_name = response.data.first_name + " " +  response.data.last_name;
            });
        },
        updateCompanyData() {
                axios.get('/companies/'+ this.$route.params.id).then((response) => {
                    console.log(response);
                    this.company = response.data;
                    this.company_name = response.data.name;
                });
            },
    },
    mounted() {
        this.getEmployeeData();         
        this.updateCompanyData();           
    }
}
</script>